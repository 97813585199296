// components/Home.js
import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Home = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Box textAlign="center" py={10}>
      <Heading as="h1" size="xl" mb={6}>
        Welcome to PF App
      </Heading>
      <Text fontSize="xl" mb={6}>
        Track your expenses with ease.
      </Text>
      {!isAuthenticated && (
        <Button as={RouterLink} to="/login" colorScheme="teal" size="lg">
          Get Started
        </Button>
      )}
    </Box>
  );
};

export default Home;