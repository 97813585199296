import React, { useEffect, useState } from "react";
import { Box, useToast, Select } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { addTransaction } from "../api/axios";
import ChatInterface from "./ChatInterface";
import { getToken, getUserRole } from "../utils/auth"

const AddTransaction = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [accountType, setAccountType] = useState('main');
  const userRole = getUserRole();

  useEffect(() => {
    const token = getToken();
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const handleTransactionComplete = async (transaction) => {
    try {
      console.log("Sending transaction:", transaction);
      const response = await addTransaction(transaction, accountType);
      console.log("Server response:", response);
      toast({
        title: "Transaction added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/transactions");
    } catch (error) {
      console.error("Error details:", error);
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);
      }
      toast({
        title: "Error adding transaction",
        description:


error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxWidth="500px" margin="auto">
      {userRole === 'Admin' && (
        <Select
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
          mb={4}
        >
          <option value="main">Main Account</option>
          <option value="second">Second Account</option>
        </Select>
      )}
      <ChatInterface onTransactionComplete={handleTransactionComplete} />
    </Box>
  );
};

export default AddTransaction;
