import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  useToast,
  HStack,
  Box,
} from "@chakra-ui/react";
import { updateTransaction, updateTransferTransaction } from "../api/axios";

const EditTransaction = ({ transaction: initialTransaction, onUpdate, onCancel }) => {
  const [transaction, setTransaction] = useState(initialTransaction);
  const [errors, setErrors] = useState({});
  const toast = useToast();

  useEffect(() => {
    setTransaction(initialTransaction);
  }, [initialTransaction]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransaction((prevTransaction) => ({
      ...prevTransaction,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!transaction.category) newErrors.category = "Category is required";
    if (!transaction.amount) newErrors.amount = "Amount is required";
    if (isNaN(parseFloat(transaction.amount)))
      newErrors.amount = "Amount must be a number";
    if (!transaction.date) newErrors.date = "Date is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      // Create a new Date object with the selected date at noon UTC
      const selectedDate = new Date(transaction.date);
      selectedDate.setUTCHours(0, 0, 0, 0);

      // Create a new object with the formatted date
      const updatedTransaction = {
        ...transaction,
        date: selectedDate.toISOString(),
      };

      let response;
      if (updatedTransaction.type === "transfer") {
        response = await updateTransferTransaction(updatedTransaction._id, updatedTransaction);
      } else {
        response = await updateTransaction(updatedTransaction._id, updatedTransaction);
      }
      
      if (response && (response.data || response.transaction)) {
        const updatedData = response.data || response.transaction;
        onUpdate(updatedData);
      } else {
        console.error("Unexpected response structure:", response);
        throw new Error("Invalid or unexpected response from server");
      }
    } catch (error) {
      console.error("Error updating transaction:", error);
      toast({
        title: "Error updating transaction",
        description: error.response?.data?.message || error.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl isInvalid={errors.date}>
          <FormLabel>Date</FormLabel>
          <Input
            name="date"
            type="date"
            value={transaction.date.split('T')[0]}
            onChange={handleChange}
          />
          {errors.date && <Box color="red.500">{errors.date}</Box>}
        </FormControl>
        <FormControl isInvalid={errors.amount}>
          <FormLabel>Amount</FormLabel>
          <Input
            name="amount"
            type="number"
            value={transaction.amount}
            onChange={handleChange}
          />
          {errors.amount && <Box color="red.500">{errors.amount}</Box>}
        </FormControl>
        <FormControl isInvalid={errors.type}>
          <FormLabel>Type</FormLabel>
          <Select
            name="type"
            value={transaction.type}
            onChange={handleChange}
          >
            <option value="income">Income</option>
            <option value="expense">Expense</option>
            <option value="transfer">Transfer</option>
          </Select>
        </FormControl>
        <FormControl isInvalid={errors.category}>
          <FormLabel>Category</FormLabel>
          <Input
            name="category"
            value={transaction.category}
            onChange={handleChange}
          />
          {errors.category && <Box color="red.500">{errors.category}</Box>}
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Input
            name="description"
            value={transaction.description}
            onChange={handleChange}
          />
        </FormControl>
        <HStack spacing={4} mt={5} mb={5} justify="flex-end">
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="submit" colorScheme="teal">
            Update Transaction
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

export default EditTransaction;
