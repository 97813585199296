// App.js

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { ChakraProvider, Box, Flex } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Transactions from "./components/Transactions";
import AddTransaction from "./components/AddTransaction";
import EditTransaction from "./components/EditTransaction";
import Login from "./components/Login";
import Register from "./components/Register";
import ExchangeRateSettings from "./components/ExchangeRateSettings";
import { AuthProvider, AuthContext } from "./context/AuthContext";
import Home from "./components/Home";

const PrivateRoute = () => {
  const { isAuthenticated, isLoading } = React.useContext(AuthContext);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

function App() {
  return (
    <AuthProvider>
      <ChakraProvider>
        <Router basename="/react-app">
          <Flex flexDirection="column" height="100vh">
            {/* Fixed Navbar */}
            <Box position="fixed" top={0} left={0} right={0} zIndex={1000}>
              <Navbar />
            </Box>
            {/* Content area with top padding to account for fixed Navbar */}
            <Box flex={1} overflowY="auto" pt="60px" px={4}>
              <Routes>
                <Route path="/" element={<Home />} />

                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/exchange-rates" element={<ExchangeRateSettings />} />
                </Route>

                <Route path="/transactions" element={<Transactions />} />
                <Route path="/add-transaction" element={<AddTransaction />} />
                <Route
                  path="/edit-transaction/:id"
                  element={<EditTransaction />}
                />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
              </Routes>
            </Box>
          </Flex>
        </Router>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
