import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import { setToken } from '../utils/auth';

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("editor");
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Sending registration data:", { email, password, role });

    try {
      const response = await api.post("/auth/register", { email, password, role });
      console.log("Registration response:", response);
      const token = response.data.token;
      setToken(token);
      console.log("Token stored in local storage:", token);

      toast({
        title: "Registration successful",
        description: "You can now login with your credentials",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      console.error("Registration error:", error);
      let errorMessage = "An error occurred";
      if (error.response) {
        console.error("Error response:", error.response.data);
        errorMessage = error.response.data.message || "Server error";
      } else if (error.request) {
        console.error("Error request:", error.request);
        errorMessage = "Network error - no response received";
      } else {
        console.error("Error message:", error.message);
        errorMessage = error.message;
      }
      toast({
        title: "Registration failed",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxWidth="400px" margin="auto">
      <Heading mb={4}>Register</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Role</FormLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              <option value="editor">Editor</option>
              <option value="admin">Admin</option>
            </Select>
          </FormControl>
          <Button type="submit" colorScheme="teal" width="100%">
            Register
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default Register;