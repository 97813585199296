// auth.js

export const setToken = (token) => {
  localStorage.setItem("token", token);
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 14); // 14 days from now
  localStorage.setItem("tokenExpiration", expirationDate.toISOString());
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpiration");
};

export const isAuthenticated = () => {
  const token = getToken();
  const expirationDate = localStorage.getItem("tokenExpiration");
  if (!token || !expirationDate) return false;
  return new Date(expirationDate) > new Date();
};

export const getUser = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.user;
  } catch (error) {
    console.error("Error parsing user from token:", error);
    return null;
  }
};

export const getUserRole = () => {
  const token = localStorage.getItem("token");
  if (!token) return null;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return payload.role;
  } catch (error) {
    console.error("Error parsing user role from token:", error);
    return null;
  }
};
