import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Select,
  InputGroup,
  InputLeftElement,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Text,
  Icon,
  Alert,
  AlertIcon,
  Spinner,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import api, { getAllExchangeRates, deleteExchangeRate } from "../api/axios";
import { useAuth } from "../context/AuthContext";

const ExchangeRateSettings = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const [exchangeRates, setExchangeRates] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]);
  const [newRate, setNewRate] = useState({
    fromCurrency: "",
    toCurrency: "",
    rate: "",
  });
  const [newCurrency, setNewCurrency] = useState({
    currency: "",
    baseRate: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [rateToDelete, setRateToDelete] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  const fetchExchangeRates = useCallback(async () => {
    try {
      const rates = await getAllExchangeRates();
      setExchangeRates(rates);
      const uniqueCurrencies = [...new Set(rates.flatMap(rate => [rate.fromCurrency, rate.toCurrency]))];
      setCurrencies(uniqueCurrencies);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      toast({
        title: "Error fetching exchange rates",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchExchangeRates();
    }
  }, [fetchExchangeRates, isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/login");
    }
  }, [isLoading, isAuthenticated, navigate]);

  useEffect(() => {
    const filtered = exchangeRates.filter(
      (rate) =>
        rate.fromCurrency.toLowerCase().includes(searchTerm.toLowerCase()) ||
        rate.toCurrency.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRates(filtered);
  }, [searchTerm, exchangeRates]);

  const handleSetExchangeRate = async (e) => {
    e.preventDefault();
    if (newRate.fromCurrency === newRate.toCurrency) {
      toast({
        title: "Invalid input",
        description: "From and To currencies must be different",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await api.post("/exchange-rates", newRate);
      toast({
        title: "Exchange rate set",
        description: `Rate set for ${newRate.fromCurrency} to ${newRate.toCurrency}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setNewRate({ fromCurrency: "", toCurrency: "", rate: "" });
      fetchExchangeRates();
    } catch (error) {
      console.error("Error setting exchange rate:", error);
      toast({
        title: "Error setting exchange rate",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddNewCurrency = async (e) => {
    e.preventDefault();
    if (currencies.includes(newCurrency.currency)) {
      toast({
        title: "Invalid input",
        description: "Currency already exists",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      await api.post("/exchange-rates/new-currency", {
        newCurrency: newCurrency.currency,
        baseCurrency: "USD",
        rate: parseFloat(newCurrency.baseRate),
      });
      toast({
        title: "New currency added",
        description: `${newCurrency.currency} added successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setNewCurrency({ currency: "", baseRate: "" });
      fetchExchangeRates();
    } catch (error) {
      console.error("Error adding new currency:", error);
      toast({
        title: "Error adding new currency",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteExchangeRate = async () => {
    if (!rateToDelete) return;
    try {
      await deleteExchangeRate(rateToDelete.fromCurrency, rateToDelete.toCurrency);
      toast({
        title: "Exchange rate deleted",
        description: `Rate for ${rateToDelete.fromCurrency} to ${rateToDelete.toCurrency} deleted successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchExchangeRates();
      onClose();
    } catch (error) {
      console.error("Error deleting exchange rate:", error);
      toast({
        title: "Error deleting exchange rate",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return null; // This will prevent any flash of content before redirecting
  }

  return (
    <Box maxWidth="1200px" margin="auto" padding={8}>
      <VStack spacing={8} align="stretch">
        <Heading size="xl">Exchange Rate Settings</Heading>

        {exchangeRates.length === 0 ? (
          <Alert status="info">
            <AlertIcon />
            No exchange rates found. Please add a new currency and set exchange rates.
          </Alert>
        ) : (
          <Box>
            <Heading size="lg" mb={4}>
              Current Exchange Rates
            </Heading>
            <InputGroup mb={4}>
              <InputLeftElement pointerEvents="none" children={<Icon as={SearchIcon} color="gray.300" />} />
              <Input
                placeholder="Search currencies..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>From Currency</Th>
                    <Th>To Currency</Th>
                    <Th>Rate</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredRates.map((rate) => (
                    <Tr key={`${rate.fromCurrency}-${rate.toCurrency}`}>
                      <Td>{rate.fromCurrency}</Td>
                      <Td>{rate.toCurrency}</Td>
                      <Td>{rate.rate}</Td>
                      <Td>
                        <Button
                          colorScheme="red"
                          size="sm"
                          onClick={() => {
                            setRateToDelete(rate);
                            onOpen();
                          }}
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}

        <Box>
          <Heading size="lg" mb={4}>
            Set Exchange Rate
          </Heading>
          <form onSubmit={handleSetExchangeRate}>
            <VStack spacing={4} align="stretch">
              <FormControl isRequired>
                <FormLabel>From Currency</FormLabel>
                <Select
                  placeholder="Select currency"
                  value={newRate.fromCurrency}
                  onChange={(e) =>
                    setNewRate({ ...newRate, fromCurrency: e.target.value })
                  }
                >
                  {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>To Currency</FormLabel>
                <Select
                  placeholder="Select currency"
                  value={newRate.toCurrency}
                  onChange={(e) =>
                    setNewRate({ ...newRate, toCurrency: e.target.value })
                  }
                >
                  {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Rate</FormLabel>
                <Input
                  type="number"
                  step="0.0001"
                  value={newRate.rate}
                  onChange={(e) =>
                    setNewRate({ ...newRate, rate: e.target.value })
                  }
                />
              </FormControl>
              <Button type="submit" colorScheme="blue">
                Set Rate
              </Button>
            </VStack>
          </form>
        </Box>

        <Box>
          <Heading size="lg" mb={4}>
            Add New Currency
          </Heading>
          <form onSubmit={handleAddNewCurrency}>
            <VStack spacing={4} align="stretch">
              <FormControl isRequired>
                <FormLabel>New Currency</FormLabel>
                <Input
                  value={newCurrency.currency}
                  onChange={(e) =>
                    setNewCurrency({ ...newCurrency, currency: e.target.value })
                  }
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Base Rate (to USD)</FormLabel>
                <Input
                  type="number"
                  step="0.0001"
                  value={newCurrency.baseRate}
                  onChange={(e) =>
                    setNewCurrency({ ...newCurrency, baseRate: e.target.value })
                  }
                />
              </FormControl>
              <Button type="submit" colorScheme="green">
                Add Currency
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Exchange Rate
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete the exchange rate for{" "}
              <Text as="span" fontWeight="bold">
                {rateToDelete?.fromCurrency}
              </Text>{" "}
              to{" "}
              <Text as="span" fontWeight="bold">
                {rateToDelete?.toCurrency}
              </Text>
              ? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteExchangeRate} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ExchangeRateSettings;
