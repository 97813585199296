// src/components/ChatInterface.js

import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  VStack,
  Input,
  Button,
  Text,
  useToast,
  List,
  ListItem,
  InputGroup,
  InputRightElement,
  Select,
  Divider,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import api, { transferFunds, getAccounts } from "../api/axios";
import { getToken } from "../utils/auth";

const ChatInterface = () => {
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [accountType, setAccountType] = useState("main");
  const [accountName, setAccountName] = useState("");
  const [initialBalance, setInitialBalance] = useState("");
  const [currency, setCurrency] = useState("USD");
  const inputRef = useRef(null);
  const toast = useToast();
  const [accounts, setAccounts] = useState([]);
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [exchangeRate, setExchangeRate] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState(null);

  const handleCreateAccount = async () => {
    try {
      const token = getToken();
      const newAccount = {
        name: accountName,
        accountType,
        balance: parseFloat(initialBalance) || 0,
        currency,
      };
      await api.post("/accounts", newAccount, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast({
        title: "Account created",
        description: `Account ${accountName} created successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setAccountName("");
      setAccountType("");
      setInitialBalance("");
      setCurrency("USD");
    } catch (error) {
      toast({
        title: "Error creating account",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getUser = async () => {
    const token = getToken();
    if (!token) {
      return null;
    }
    try {
      const response = await api.get("/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  const fetchTransactions = useCallback(async () => {
    try {
      const token = getToken();
      if (!token) {
        console.error("No token found");
        toast({
          title: "Authentication Error",
          description: "Please log in to view transactions",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const response = await api.get(
        `/transactions?accountType=${accountType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data && Array.isArray(response.data.transactions)) {
        setTransactions(response.data.transactions);
        updateCategories(response.data.transactions);
      } else {
        console.error("Unexpected response format:", response.data);
        toast({
          title: "Error fetching transactions",
          description: "Received unexpected data format",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast({
        title: "Error fetching transactions",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast, accountType]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    if (transactions.length > 0) {
      updateCategories(transactions);
    }
  }, [transactions]);

  useEffect(() => {
    const initializeComponent = async () => {
      const user = await getUser();
      if (user) {
        fetchTransactions();
      } else {
        console.error("User not authenticated");
        toast({
          title: "Authentication Error",
          description: "Please log in to view transactions",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    initializeComponent();
  }, [fetchTransactions, toast]);

  useEffect(() => {
    if (input.trim()) {
      const words = input.trim().split(" ");
      const lastWord = words[words.length - 1].toLowerCase();
      const filteredSuggestions = categories
        .filter((cat) => cat.toLowerCase().includes(lastWord))
        .slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [input, categories]);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const fetchedAccounts = await getAccounts();
        setAccounts(fetchedAccounts);
      } catch (error) {
        console.error("Error fetching accounts:", error);
        toast({
          title: "Error fetching accounts",
          description: error.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchAccounts();
  }, [toast]);

  useEffect(() => {
    const fetchExchangeRate = async (fromAccountId, toAccountId) => {
      if (fromAccountId && toAccountId) {
        const fromAccount = accounts.find((acc) => acc._id === fromAccountId);
        const toAccount = accounts.find((acc) => acc._id === toAccountId);

        if (
          fromAccount &&
          toAccount &&
          fromAccount.currency !== toAccount.currency
        ) {
          try {
            const response = await api.get(
              `/exchange-rates?fromCurrency=${fromAccount.currency}&toCurrency=${toAccount.currency}`
            );
            setExchangeRate(response.data.rate);
          } catch (error) {
            console.error("Error fetching exchange rate:", error);
            toast({
              title: "Error fetching exchange rate",
              description: error.response?.data?.message || "An error occurred",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        } else {
          setExchangeRate(null);
        }
      }
    };

    if (fromAccount && toAccount) {
      fetchExchangeRate(fromAccount, toAccount);
    }
  }, [fromAccount, toAccount, accounts, toast]);

  useEffect(() => {
    if (exchangeRate && transferAmount) {
      setConvertedAmount(
        (parseFloat(transferAmount) * exchangeRate).toFixed(2)
      );
    } else {
      setConvertedAmount(null);
    }
  }, [exchangeRate, transferAmount]);

  const updateCategories = (transactions) => {
    if (!Array.isArray(transactions)) {
      console.error(
        "updateCategories: transactions is not an array",
        transactions
      );
      return;
    }
    const categoryCount = {};
    transactions.forEach((transaction) => {
      if (transaction && transaction.category) {
        categoryCount[transaction.category] =
          (categoryCount[transaction.category] || 0) + 1;
      }
    });
    const sortedCategories = Object.keys(categoryCount).sort(
      (a, b) => categoryCount[b] - categoryCount[a]
    );
    setCategories(sortedCategories);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
  };

  const getAccountIdByType = async (accountType) => {
    try {
      const token = getToken();
      const response = await api.get(`/accounts?accountType=${accountType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.length > 0) {
        return response.data[0]._id;
      } else {
        throw new Error(`No account found for the given type: ${accountType}`);
      }
    } catch (error) {
      console.error("Error fetching account ID:", error);
      toast({
        title: "Error fetching account ID",
        description:
          error.response?.data?.message || error.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const handleSubmit = async () => {
    const parts = input.trim().split(" ");
    let amount, category, description;

    if (parts.length >= 2) {
      if (!isNaN(parts[0])) {
        amount = parseFloat(parts[0]);
        category = parts[1];
        description = parts.slice(2).join(" ");
      } else if (!isNaN(parts[parts.length - 1])) {
        amount = parseFloat(parts[parts.length - 1]);
        category = parts[0];
        description = parts.slice(1, -1).join(" ");
      }
    }

    if (amount && category) {
      try {
        const token = getToken();
        const accountId = await getAccountIdByType(accountType);
        const newTransaction = {
          accountId,
          accountType,
          date: new Date(),
          amount: Math.abs(amount),
          type: amount > 0 ? "income" : "expense",
          category,
          description: description || undefined,
          paymentMethod: "default",
          tags: [],
        };
        await api.post(`/transactions`, newTransaction, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        toast({
          title: "Transaction added",
          description: `${amount} for ${category}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setInput("");
        await fetchTransactions();
      } catch (error) {
        console.error("Error adding transaction:", error);
        toast({
          title: "Error adding transaction",
          description: error.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Invalid input",
        description: "Please enter an amount and category",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    } else if (e.key === "Tab" && suggestions.length > 0) {
      e.preventDefault();
      handleSuggestionClick(suggestions[0]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const words = input.trim().split(" ");
    words[words.length - 1] = suggestion;
    setInput(words.join(" ") + " ");
    inputRef.current.focus();
  };

  const handleTransfer = async () => {
    try {
      const fromAcc = accounts.find((acc) => acc._id === fromAccount);
      const toAcc = accounts.find((acc) => acc._id === toAccount);

      await transferFunds(
        fromAccount,
        toAccount,
        parseFloat(transferAmount),
        parseFloat(convertedAmount),
        fromAcc.currency,
        toAcc.currency,
        exchangeRate
      );
      toast({
        title: "Transfer successful",
        description: `Transferred ${transferAmount} ${fromAcc.currency} from ${
          fromAcc.name
        } to ${toAcc.name} (${convertedAmount} ${toAcc.currency})`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setFromAccount("");
      setToAccount("");
      setTransferAmount("");
      setExchangeRate(null);
      setConvertedAmount(null);
      fetchTransactions();
    } catch (error) {
      console.error("Error transferring funds:", error);
      toast({
        title: "Error transferring funds",
        description: error.userFriendlyMessage || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <VStack spacing={4} ml={4} mr={4} mt={8} align="stretch">
        <Select
          size="lg"
          fontSize="lg"
          value={accountType}
          onChange={handleAccountTypeChange}
        >
          <option value="main">Main Account</option>
          <option value="aik">AIK</option>
          <option value="unicredit">Unicredit</option>
        </Select>

        <InputGroup size="lg">
          <Input
            ref={inputRef}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="50 groceries"
            pr="4.5rem"
            color="gray.700"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleSubmit}>
              Add
            </Button>
          </InputRightElement>
        </InputGroup>

        {suggestions.length > 0 && (
          <Box borderWidth={1} borderRadius="md" p={2}>
            <Text fontSize="sm" color="gray.400" mb={2}>
              Suggestions:
            </Text>
            <List spacing={1}>
              {suggestions.map((suggestion, index) => (
                <ListItem
                  key={index}
                  cursor="pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                  _hover={{ bg: "gray.100" }}
                  p={1}
                >
                  {suggestion}
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Box mb={4}>
          <Text fontWeight="bold" color="gray.500" mb={2} mt={4}>
            Recent Transactions:
          </Text>
          {transactions.length > 0 ? (
            <List spacing={1}>
              {transactions.slice(0, 5).map((transaction) => (
                <ListItem color="gray.400" key={transaction._id}>
                  {transaction.type === "transfer" ? (
                    <Text>
                      Transfer: {transaction.amount} {transaction.currency} to{" "}
                      {accounts.find(acc => acc._id === transaction.transferToAccountId)?.name}
                      {transaction.exchangeRate && (
                        <span>
                          {" "}
                          (Converted: {transaction.convertedAmount}{" "}
                          {accounts.find(acc => acc._id === transaction.transferToAccountId)?.currency},
                          Rate: {transaction.exchangeRate})
                        </span>
                      )}
                    </Text>
                  ) : (
                    <Text>
                      {`${transaction.amount} ${transaction.currency} - ${transaction.category} - ${
                        transaction.description || "No description"
                      }`}
                    </Text>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <Text>No recent transactions.</Text>
          )}
        </Box>
        <Divider />
        <Box mt={4} mb={8}>
          <Text fontWeight="bold" mb={2}>
            Transfer Funds
          </Text>
          <FormControl>
            <FormLabel>From Account</FormLabel>
            <Select
              value={fromAccount}
              onChange={(e) => setFromAccount(e.target.value)}
              mb={2}
            >
              <option value="">Select account</option>
              {accounts.map((account) => (
                <option key={account._id} value={account._id}>
                  {account.name} ({account.currency})
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>To Account</FormLabel>
            <Select
              value={toAccount}
              onChange={(e) => setToAccount(e.target.value)}
              mb={2}
            >
              <option value="">Select account</option>
              {accounts.map((account) => (
                <option key={account._id} value={account._id}>
                  {account.name} ({account.currency})
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Amount</FormLabel>
            <Input
              type="number"
              value={transferAmount}
              onChange={(e) => {
                setTransferAmount(e.target.value);
                if (exchangeRate) {
                  setConvertedAmount((parseFloat(e.target.value) * exchangeRate).toFixed(2));
                }
              }}
              mb={2}
            />
          </FormControl>
          {exchangeRate && (
            <Text mb={2}>
              Exchange Rate: 1{" "}
              {accounts.find((acc) => acc._id === fromAccount)?.currency} ={" "}
              {exchangeRate}{" "}
              {accounts.find((acc) => acc._id === toAccount)?.currency}
            </Text>
          )}
          {exchangeRate && transferAmount && (
            <FormControl>
              <FormLabel>Converted Amount</FormLabel>
              <Input
                type="number"
                value={convertedAmount}
                isReadOnly
                mb={2}
              />
            </FormControl>
          )}
          <Button
            onClick={handleTransfer}
            colorScheme="blue"
            isDisabled={!fromAccount || !toAccount || !transferAmount || !convertedAmount}
          >
            Transfer
          </Button>
        </Box>
        <Divider />
        <Box mt={4} mb={8}>
          <Text fontWeight="bold" mb={2}>
            Create New Account
          </Text>
          <Input
            placeholder="Account Name"
            value={accountName || ""}
            onChange={(e) => setAccountName(e.target.value)}
            mb={2}
          />
          <Select
            placeholder="Account Type"
            value={accountType || "main"}
            onChange={(e) => setAccountType(e.target.value)}
            mb={2}
          >
            <option value="unicredit">Unicredit</option>
          </Select>
          <Input
            placeholder="Initial Balance"
            value={initialBalance || ""}
            onChange={(e) => setInitialBalance(e.target.value)}
            mb={2}
          />
          <Select
            placeholder="Currency"
            value={currency || "USD"}
            onChange={(e) => setCurrency(e.target.value)}
            mb={2}
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="DIN">DIN</option>
          </Select>
          <Button onClick={handleCreateAccount}>Create Account</Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default ChatInterface;
