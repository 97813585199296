import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  VStack,
  HStack,
  Text,
  Badge,
  Button,
  useToast,
  useBreakpointValue,
  Select,
  Input,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import EditTransaction from "./EditTransaction";
import { getTransactions, deleteTransaction } from "../api/axios";
import { useAuth } from "../context/AuthContext";
import { getUserRole } from "../utils/auth";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

const Transactions = ({ onTotalWealthUpdate }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [month, setMonth] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [transactionToEdit, setTransactionToEdit] = useState(null);
  const [accountType, setAccountType] = useState("main");
  const toast = useToast();
  const navigate = useNavigate();
  const { isLoading: authLoading } = useAuth();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [expenseData, setExpenseData] = useState([]);
  const [incomeVsExpense, setIncomeVsExpense] = useState([]);
  const userRole = getUserRole();

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    setMonth(selectedMonth);
    setStartDate("");
    setEndDate("");
    setPage(1);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setMonth("");
    setPage(1);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setMonth("");
    setPage(1);
  };

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    try {
      const params = { page, accountType };
      if (month) {
        params.month = month;
      } else if (startDate && endDate) {
        params.startDate = startDate;
        params.endDate = endDate;
      }

      // Fetch paginated transactions
      const paginatedResponse = await getTransactions(params);
      setTransactions(paginatedResponse.data.transactions || []);
      setTotalPages(paginatedResponse.data.totalPages || 1);

      // Fetch all transactions for the current month
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      const allTransactionsResponse = await getTransactions({
        month: currentMonth,
        year: currentYear,
        accountType,
      });

      const allTransactions = allTransactionsResponse.data || [];
      console.log("All transactions fetched:", allTransactions.length);

      // Process data for pie chart
      const expensesByCategory = allTransactions
        .filter((t) => t.type === "expense")
        .reduce((acc, t) => {
          acc[t.category] = (acc[t.category] || 0) + t.amount;
          return acc;
        }, {});

      const chartData = Object.entries(expensesByCategory).map(
        ([name, value]) => ({ name, value })
      );
      setExpenseData(chartData);

      // Calculate income vs expense
      const income = allTransactions
        .filter((t) => t.type === "income")
        .reduce((sum, t) => sum + parseFloat(t.amount), 0);
      const expense = allTransactions
        .filter((t) => t.type === "expense")
        .reduce((sum, t) => sum + parseFloat(t.amount), 0);
      setIncomeVsExpense([
        { name: "Income", amount: income },
        { name: "Expense", amount: expense },
      ]);

      console.log("Total Income:", income);
      console.log("Total Expense:", expense);
      console.log("Number of transactions:", allTransactions.length);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      let errorMessage = "An error occurred while fetching transactions.";
      if (error.response) {
        if (error.response.status === 500) {
          errorMessage =
            "Server error. Please try again later or contact support.";
        } else {
          errorMessage =
            error.response.data?.message || "An unexpected error occurred.";
        }
      } else if (error.request) {
        errorMessage =
          "No response from server. Please check your internet connection.";
      }
      toast({
        title: "Error fetching transactions",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setTransactions([]);
      setExpenseData([]);
      setIncomeVsExpense([]);
    } finally {
      setIsLoading(false);
    }
  }, [page, month, startDate, endDate, accountType, toast]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchData = async () => {
      try {
        await fetchTransactions();
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          console.error("Error:", error);
        }
      }
    };

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [fetchTransactions, navigate]);

  const handleDelete = async (id) => {
    try {
      await deleteTransaction(id, accountType);
      fetchTransactions();
      toast({
        title: "Transaction deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting transaction",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onDeleteClose();
  };

  const confirmDelete = (id) => {
    setTransactionToDelete(id);
    onDeleteOpen();
  };

  const handleEdit = (transaction) => {
    setTransactionToEdit(transaction);
    onEditOpen();
  };

  const handleUpdateTransaction = (updatedTransaction) => {
    setTransactions(
      transactions.map((t) =>
        t._id === updatedTransaction._id ? updatedTransaction : t
      )
    );
    onEditClose();
    toast({
      title: "Transaction updated",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const formatAmount = (amount) => {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884D8",
    "#82ca9d",
  ];

  const renderIncomeVsExpenseChart = () => (
    <Box mt={4} mb={4} height={["300px", "400px"]}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={incomeVsExpense}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={(value) => `${formatAmount(value)}`} />
          <Bar dataKey="amount" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );

  const renderPieChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={expenseData}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={({ name, percent }) =>
            `${name} ${(percent * 100).toFixed(0)}%`
          }
        >
          {expenseData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `${formatAmount(value)}`} />
      </PieChart>
    </ResponsiveContainer>
  );

  if (isLoading || authLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box>
      <Heading mb={4}>Transactions</Heading>
      {userRole === "Admin" && (
        <Select
          value={accountType}
          onChange={(e) => setAccountType(e.target.value)}
          mb={4}
        >
          <option value="main">Main Account</option>
          <option value="second">Second Account</option>
        </Select>
      )}
      <Flex mb={4} flexDirection={isMobile ? "column" : "row"}>
        <Select
          placeholder="Select month"
          value={month}
          onChange={handleMonthChange}
          mr={isMobile ? 0 : 2}
          mb={isMobile ? 2 : 0}
        >
          {[...Array(12)].map((_, i) => (
            <option key={i} value={i + 1}>
              {new Date(0, i).toLocaleString("default", { month: "long" })}
            </option>
          ))}
        </Select>
        <Input
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          mr={isMobile ? 0 : 2}
          mb={isMobile ? 2 : 0}
        />
        <Input type="date" value={endDate} onChange={handleEndDateChange} />
      </Flex>
      <Box bg="white" p={4} borderRadius="md" boxShadow="md">
        <Heading size="md" mb={2}>
          Summary ({new Date().toLocaleString("default", { month: "long" })})
        </Heading>
        <Text>
          Total Income: {formatAmount(incomeVsExpense[0]?.amount || 0)}
        </Text>
        <Text>
          Total Expenses: {formatAmount(incomeVsExpense[1]?.amount || 0)}
        </Text>
        <Text>
          Balance:{" "}
          <strong>
            {formatAmount(
              (incomeVsExpense[0]?.amount || 0) -
                (incomeVsExpense[1]?.amount || 0)
            )}
          </strong>
        </Text>
      </Box>
      {(expenseData.length > 0 || incomeVsExpense.length > 0) && (
        <Flex
          direction={["column", "row"]}
          justify="space-between"
          mt={4}
          mb={4}
        >
          <Box width={["100%", "48%"]}>
            <Heading size="md" mb={2}>
              Income vs Expense
            </Heading>
            {renderIncomeVsExpenseChart()}
          </Box>
          <Box width={["100%", "48%"]}>
            <Heading size="md" mb={2}>
              Expenses by Category
            </Heading>
            {renderPieChart()}
          </Box>
        </Flex>
      )}
      {transactions.length > 0 ? (
        <VStack spacing={4} align="stretch">
          {transactions.map((transaction) => (
            <Box
              key={transaction._id}
              borderWidth={1}
              borderRadius="lg"
              p={4}
              shadow="md"
            >
              <VStack align="start" spacing={2}>
                <HStack justify="space-between" width="100%">
                  <Text fontWeight="bold">{formatDate(transaction.date)}</Text>
                  <Badge
                    colorScheme={
                      transaction.type === "income" ? "green" : "red"
                    }
                  >
                    {transaction.type}
                  </Badge>
                </HStack>
                <Text>{transaction.category}</Text>
                <Text fontWeight="bold">
                  {formatAmount(transaction.amount)}
                </Text>
                <Text>{transaction.description}</Text>
                <HStack>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => handleEdit(transaction)}
                  >
                    Edit
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => confirmDelete(transaction._id)}
                  >
                    Delete
                  </Button>
                </HStack>
              </VStack>
            </Box>
          ))}
        </VStack>
      ) : (
        <Text>No transactions found.</Text>
      )}
      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => setPage((p) => Math.max(1, p - 1))}
          isDisabled={page === 1}
        >
          Previous
        </Button>
        <Text>
          Page {page} of {totalPages}
        </Text>
        <Button
          onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
          isDisabled={page === totalPages}
        >
          Next
        </Button>
      </Flex>

      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Transaction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this transaction?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => handleDelete(transactionToDelete)}
            >
              Yes
            </Button>
            <Button variant="ghost" onClick={onDeleteClose}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Transaction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {transactionToEdit && (
              <EditTransaction
                transaction={transactionToEdit}
                onUpdate={handleUpdateTransaction}
                onCancel={onEditClose}
                accountType={accountType}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Transactions;
